import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

const messages = {
  en: {
    login_title: "Login",
    forgot_password_title: "Forgot Password",
    email: "Email",
    "Leadership Team": "Leadership Team",
    password: "Password",
    firstname: "First name",
    lastname: "Last name",
    login: "Login",
    login_reset: "Login ?",
    back_to_login: "Back to login",
    reset: "Reset",
    auto_logout: "Automatic logout",
    auto_logout_after_min: "You will be logged out automatically in $min minutes.",
    continue_working: "Continue working",
    change_password: "Change password",
    set_new_password: "Set a new password",
    current_password: "Current password",
    new_password: "New password",
    confirm_new_password: "Confirm new password",
    set_password: "Set password",
    reset_password: "Reset password",
    send_password_reset_email: "Send password reset email",
    forget_password: "Forgot your password ?",
    password_settings: "Password settings",
    create_password_auto: "Auto-generate password and send password reset email to new user",
    create_password_manual: "Let me create the password",
    reset_password_receive_email: "The user will receive an email to set a new password.",
    error_create_user_send_email:
      "User management error: Reset password email could not be send to the user, please try again!",
    success_create_user_send_email: "Email with password instructions was send to the user.",
    success_change_password: "Password successfully changed.",
    password_send_successfully:
      "A password reset email was send out to your email address, please check your inbox. If you did not get an email please verify with your administrator or the ValueWorks support that the account exists.",
    no_such_user: "No user found for the given credentials",
    my_dashboard: "My Dashboard",
    password_send_error:
      "There was an error sending out the password reset email. Please try again or contact the ValueWorks support.",
    integration_dashboard: "Integrations & APIs",
    your_integrations: "Your Integrations",
    integration_generic_error: "The integration requires a new sign in.",
    restart_integration: "Restart integration",
    integrated: "Integrated",
    client_id: "Client id",
    client_secret: "Client secret",
    _title: "Api Key",
    enter_clientid_clientsecret: "Enter credentials",
    favorite_kpis: "Favorite KPIs",
    text_dev_chart:
      "Note: Tickets and issues without a relevant label will be categorized as “n/a”. Please assign a label to all issues in your IT project software.",
    add_to_custom_dashboard: "Add to Custom Dashboard",
    remove_from_custom_dashboard: "Remove from Custom Dashboard",
    add_custom_category: "Add custom category",
    delete_custom_category: "Delete custom category",
    delete_custom_category_confirmation: "Confirm delete",
    confirm_delete_custom_category:
      "Are you sure you want to delete this custom category? This action is permanent and cannot be reversed.",
    select_custom_category: "Select custom category",
    add_custom_kpi: "Add custom KPI",
    add_custom_strategic_kpi: "Add custom strategic KPI",
    add_custom_operational_kpi: "Add custom operational KPI",
    add_custom_custom_kpi: "Add custom KPI",
    edit_objective: "Edit Objective",
    edit_key_result: "Edit Key Result",
    edit_custom_operational_kpi: "Edit custom operational KPI",
    edit_custom_strategic_kpi: "Edit custom strategic KPI",
    edit_custom_financial_kpi: "Edit custom financial KPI",
    edit_custom_custom_kpi: "Edit custom KPI",
    review_operational_kpi: "Review operational KPI",
    organizational_structure: "Organizational Structure",
    create_new_structure: "Edit",
    save_new_structure: "Done",
    value: "Value",
    update_values: "Update values",
    update_reporting_values: "Update reporting values",
    update_planning_values: "Update planning values",
    update_kpi_reporting_values: "Update KPI reporting values",
    update_kpi_planning_values: "Update KPI planning values",
    month_name_01: "Jan",
    month_name_02: "Feb",
    month_name_03: "Mar",
    month_name_04: "Apr",
    month_name_05: "May",
    month_name_06: "Jun",
    month_name_07: "Jul",
    month_name_08: "Aug",
    month_name_09: "Sep",
    month_name_10: "Oct",
    month_name_11: "Nov",
    month_name_12: "Dec",
    add_department: "Add a new department or team",
    delete_department: "Delete",
    planning_value: "Planning",
    last_month: "Last month",
    trend_reference: "Trend",
    current_month_value: "Current month",
    by_trend: "By Trend",
    by_planning: "By Planning",
    edit_kpi: "Edit KPI",
    high_performing_kpis: "High performing KPIs",
    low_performing_kpis: "Low performing KPIs",
    review_strategic_kpi: "Review strategic KPI",
    review_financial_kpi: "Review financial KPI",
    review_custom_kpi: "Review custom KPI",
    confirm_delete_custom_kpi:
      "Are you sure you want to delete this KPI? This action is permanent and cannot be reversed.",
    remove_custom_kpi: "Remove custom KPI",
    category_name: "Category name",
    rename_dashboard: "Rename dashboard",
    all_custom_dashboards: "All custom dashboards",
    kpi_name: "KPI name",
    kpi_description: "KPI description",
    kpi_unit: "Unit",
    manage_dashboard_access: "Manage Access",
    current_user: "Current User",
    delete_custom_dashboard_title: "Delete custom dashboard",
    delete_custom_dashboard:
      "Are you sure you want to delete this custom dashboard? This action is permanent and cannot be reversed.",
    dashboard_position: "Dashboard should come after",
    included_in_okr: "Included in OKRs",
    kpi_measurement_cumulative: "Cumulative",
    kpi_measurement_average: "Average",
    kpi_measurement_dd: "Last value",
    kpi_measurement: "Measurement",
    kpi_direction_of_valuation: "Direction of valuation",
    kpi_included_in_planning: "Included in planning",
    kpi_higher_is_better: "Higher is better",
    kpi_lower_is_better: "Lower is better",
    included_in_planning: "Included in Planning",
    add_existing_kpi: "Add existing KPI",
    adapt_dashboard_note_1:
      "The dashboard will be shown under 'Reporting' in the navigation on the left.",
    adapt_dashboard_note_2:
      "You can add both existing KPIs or define new ones for your additional dashboard.",
    add_custom_category_note:
      "The category will be shown on the dashboard after a KPI is added to it.",
    delete_custom_category_note:
      "By clicking 'delete', all custom KPIs in this category will be deleted as well. If you want to keep KPIs in this category, please assign them to a different category before deleting this one.",
    actions: "Actions",
    back: "Back",
    title: "Title",
    dashboard_options: "Dashboard options",
    add_new_category: "Add new category",
    edit_custom_categories: "Edit Custom Categories",
    add_existing_kpis: "Add existing KPIs",
    save_and_add_another: "Save and add another",
    account_settings: "Company Settings",
    account_settings_user_info: "My user information",
    account_settings_kpi_list: "KPI list",
    account_settings_user_list: "User list",
    account_settings_sync_success: "KPI meta data successfully updated",
    account_settings_sync_error: "Synchronization error",
    account_settings_sync_central: "ValueWorks Central Service",
    account_settings_sync_kpi_meta: "Get latest KPIs and benchmarks",
    account_settings_administrator_checkbox: "Company Administrator",
    account_settings_administrator_description:
      "Grants all permissions. Only administrators are allowed to adjust company settings and manage users.",
    select_role: "Select a role",
    data_input: "Manual Data Upload",
    execution: "Execution",
    unlock_navigation: "Unlock Navigation",
    lock_navigation: "Lock Navigation",
    planning: "Planning",
    deviation: "Deviation",
    financial_planning: "Financial Planning",
    operational_planning: "Operational Planning",
    operational_kpis: "Operational KPIs",
    reporting: "Reporting",
    operational_dashboard: "Operational Dashboard",
    strategic_dashboard: "Strategic Dashboard",
    financial_dashboard: "Financial Dashboard",
    custom_dashboard: "Custom Dashboard",
    profit_loss_analysis: "Profit & Loss Analysis",
    actual_comparison: "Actual comparison",
    actual_overview: "Actual overview",
    milestones: "Milestones",
    show_pl_sub_categories: "Show subcategories",
    margins: "Margins",
    show_margins: "Show margins",
    show_resolved_actions: "Show resolved actions",
    show_open_actions: "Show open actions",
    due: "Due",
    current: "Current",
    lose_changes: "Do you want to lose all changes?",
    change_log: "Change log",
    not_saved: "Don't save",
    list_view_financial: "List View",
    nodata_buckets: "There is no data in the buckets",
    nodata_marimekko_financial:
      "The response is empty. There is no data to display for the current month.",
    average_listview: "AVERAGE",
    total_listview: "AS A % OF TOTAL",
    no_data_listview:
      "No detailed data available; Please integrate relevant system or manually upload data.",
    no_data_listview_calculated_kpi:
      "This KPI is calculated from other KPIs within ValueWorks. Please check the KPI background for more information.",
    no_data_conversion_matrix:
      "No detailed data available; Please integrate relevant system, manually upload data or select another comparison date.",
    adapt_forecast: "Adapt forecast",
    show_planning_delta: "Show planning delta",
    previous_forecast: "Previous forecast",
    linear_projection: "Projection",
    add_new_forecast: "Add new forecast",
    vs_planning_per: "vs. planning (%)",
    save_forecast: "Save forecast",
    text_save_changes: "Without saving all changes will be lost.",
    comments_dialogue:
      "Without saving all changes will be lost. Please make sure to save your comments.",
    comments_disabled_mentions:
      "This user does not have access to the content you’re currently viewing. You can change this via Settings > User Management.",
    ytd: "YTD",
    ytd_cum: "YTD ∑",
    ytd_avg_cum: "YTD Ø / ∑",
    year_to_date: "Year to date",
    full_year_projection: "Full year projection",
    full_year_projection_sum: "Full year projection ∑",
    full_year_projection_avg_cum: "Full year projection Ø / ∑",
    customize_table: "Customize table",
    customize_okr_tables: "Customize OKR Tables",
    customize_list_views: "Customize List Views",
    is_cumulated: "Cumulated",
    is_average: "Average",
    is_dday: "Last value",
    cumulated: "∑",
    average: "Ø",
    dday: "last",
    avg_cum: "Ø / ∑ ",
    cum_avg: "∑ / Ø",
    planned: "Planned",
    plan: "Plan",
    actual: "Actual",
    start: "Start",
    end: "End",
    ok: "OK",
    target: "Target",
    progress: "Progress",
    add_milestones: "+ Add milestone",
    all_departments: "All departments",
    add_to_objective: "+ Add to Objective",
    retry_again: "Retry again.",
    okr_gpt_recommendation_confirmation_title: "Note for using OKR recommendation engine.",
    okr_gpt_recommendation_confirmation_message:
      "Please be aware that by using this functionality, you will be sharing your current Objective and the names of related Key Results (i.e., no actuals, target values etc.) with a third-party provider.",
    okr_gpt_recommendation_confirmation_note:
      "If this functionality is available, this means that your system admin has enabled the feature. Use it at your own discretion.",
    do_not_show_this_message_again: "Don't show this message again.",
    departments: "Departments",
    select_department: "Select Department",
    open_milestones: "Show open milestones",
    finished_milestones: "Show finished milestones",
    last_year: "Last Year",
    kpi_background: "KPI Background",
    overall_progress: "Overall Progress",
    objectives_by_status: "Objectives by Status",
    show_status: "Show Status",
    integration_status: "Integration Status",
    re_run: "Re-run",
    running: "Running...",
    rerun_status: "Rerun status",
    the_total_runtime_was: "The total runtime was",
    collection: "Data collection",
    pretransformation: "Pre-transformation",
    transformation: "Transformation",
    calculation: "Calculation run",
    confirm_delete_objective:
      "Please confirm that you want to delete this objective - this cannot be undone.",
    confirm_delete_user:
      "Please confirm that you want to delete this user - this cannot be undone.",
    confirm_delete_key_result:
      "Please confirm that you want to delete the key result '$name' - this cannot be undone.",
    confirm_delete_schedule:
      "Please confirm that you want to delete this schedule with all reminders - this cannot be undone.",
    confirm_delete_reminder:
      "Please confirm that you want to delete this reminder - this cannot be undone.",
    key_results_by_status: "Key Results by Status",
    key_result_kpi_start_cum_info: "This is a cumulated KPI. Suggested start is always 0.",
    sum: "SUM",
    key_result_kpi_target_cum_info:
      "This is a cumulated KPI. Suggested target is the sum of monthly planning values in the Objective time frame.",
    key_result_kpi_start_avg_info:
      "This is an average KPI. Suggested start is the actual reporting value of the month before the Objective time frame.",
    key_result_kpi_target_avg_info:
      "This is an average KPI. Suggested target is the planning value of the last month in the Objective time frame.",
    key_result_kpi_start_dd_info:
      "This is an last value KPI. Suggested start is the actual reporting value of the month before the Objective time frame.",
    key_result_kpi_target_dd_info:
      "This is an last value KPI. Suggested target is the planning value of the last month in the Objective time frame.",
    key_result_link_progress_to_kpi: "Link progress to KPI",
    key_result_linked_progress_to_kpi:
      "This KPI is linked. The actual & progress are directly linked to the data in reporting and can not be changed here.",
    key_result_unlinked_progress_to_kpi:
      "This KPI is not linked (i.e., it is a leading or lagging KPI). The actual value of the Key Result can be adjusted manually.",
    key_result_link_progress_to_kpi_info:
      "In the case of leading/lagging KPIs, you might want to update the Key Result progress manually. Unlinking the KPI will allow you to steer the progress, while maintaining a connection to the relevant KPI.",
    no_planning_suggestion: "No planning suggestion",
    no_reporting_suggestion: "No reporting suggestion",
    show_other_members_key_results: "Show Key Results of other members",
    hide_other_members_key_results: "Hide Key Results of other members",
    keyresult_create_kpi_confidential_warning:
      "This KPI is confidential, the new key result may not be visible to everyone in your organization.",
    projection: "Targeted progress",
    forecast: "Forecast",
    financial_marimekko: "Relative Distribution",
    currencies: "Currencies",
    currency_title: "Currency",
    exchange_rate: "Exchange Rate",
    add_exchange_rate: "Add exchange rate",
    add_exchange_rate_plus: "+ Add exchange rate",
    corporate_checkbox: "Corporate",
    currency_code: "Currency Code",
    corporate_currency: "Corporate",
    exchange: "Exchange Rate",
    add_currency: "Add currency",
    kpi_label: "KPI:",
    add_kpi: "Add KPI",
    assign_kpi: "Assign",
    to_custom: "to custom dashboards",
    adapt_dashboard: "Adapt dashboard",
    add_custom_dashboard: "Add custom dashboard",
    dashboard_name: "Dashboard name",
    all_currencies: "Select a currency",
    variance: "Variance",
    var: "Deviation",
    var_per: "Deviation (%)",
    current_value: "Current value",
    benchmark: "Benchmark",
    external_benchmark: "External Benchmark",
    no_benchmark_available: "Benchmark not available",
    top_25_per: "Top 25%",
    top_5_per: "Top 5%",
    bottom_25_per: "Bottom 25%",
    bottom_5_per: "Bottom 5%",
    average_50_per: "Average 50%",
    valueTop5: "Top 5%",
    valueTop25: "Top 25%",
    valueMedian50: "Average 50%",
    valueBottom25: "Bottom 25%",
    valueBottom5: "Bottom 5%",
    analyze_root_causes: "Analyze root causes",
    start_root_cause_analysis: "Start the root cause analysis to add an action for this KPI.",
    assigned_to: "Assigned to",
    owner: "Owner",
    owners: "Owners",
    see_root_analysis: "See root analysis",
    created_by: "Created by",
    action: "Action",
    assigned_user: "Assigned User",
    responsible: "Responsible",
    due_date: "Due Date",
    created: "Created",
    comments: "Comments",
    edited: "Edited",
    reply: "Reply",
    delete_comment: "Delete comment",
    delete_comment_dialogue:
      "Are you sure you want to delete this comment? This action is permanent and cannot be reversed.",
    add_comment: "Add comment",
    add_new_comment: "Add new comment",
    load_more: "Load more",
    root_cause_questions: "Root cause questions",
    strongly_disagree: "Strongly Disagree",
    disagree: "Disagree",
    neither_agree_nor_disagree: "Neither agree nor disagree",
    agree: "Agree",
    strongly_agree: "Strongly Agree",
    rca_questions_missing: "There are no root cause questions for this KPI yet.",
    get_recommendations: "Calculate Recommendations",
    no_rootcause_identified: "Based on your answers we were not able to identify a root cause.",
    back_to_detail_view: "Back to KPI overview",
    high: "High",
    low: "Low",
    mid: "Medium",
    corporate: "Corporate",
    overview: "Overview",
    okr: "OKR",
    okr_progress_list: "Progress list",
    empty_team_analytics: "No team analytics data received.",
    recipients: "recipients",
    Recipients: "Recipients",
    recipient_1: "recipient",
    okr_scheduler: "OKR Scheduler",
    create_new_schedule_plan: "Create new schedule plan",
    add_schedule: "Add schedule",
    add_first_schedule: "Add your first schedule",
    add_reminder: "Add reminder",
    add_reminder_button: "+ Add reminder",
    edit_reminder: "Edit reminder",
    send_reminder: "Send reminder",
    schedule_plan: "Schedule plan",
    select_schedule_plan: "Select schedule plan",
    select_schedule_plan_for_year: "Select a schedule plan for this year",
    select: "Select",
    create_and_select: "Create and select",
    reminder_message: "Message",
    create_reminder_message_placeholder: "Enter a reminder message ...",
    recipient: "Recipient",
    okr_reminder_recipient_info:
      "The group or user who will receive the reminder email notifications.",
    link_to_page: "Link to page",
    okr_reminder_link_to_info:
      "The reminder email contains a URL that links to the selected OKR page.",
    frequency: "Frequency",
    due_on_the: "Due on the",
    on_the: "on the",
    due_every: "Due every",
    every: "every",
    objectives: "Objectives",
    my_okrs: "My OKRs",
    corporate_objectives: "Corporate Objectives",
    department: "Department",
    objectives_key_results: "My Objectives and Key Results - ",
    objectives_and_key_results: "Objectives and Key Results",
    user_corporate_okrs: "Corporate OKRs",
    user_team_okrs: "Team OKRs",
    not_established: "Not established",
    create_objective_parent_link_note:
      "The link will be displayed on the OKR overview page and can be changed later.",
    select_parent_for_child_view_message:
      "You cannot link more than two Objectives with a higher hierarchy level to one Team Objective. Please unlink, before adding a new link.",
    attach_children_to_parent_view_message:
      "This Objective is already linked to two Objectives higher in the hierarchy. Please unlink, before adding a new link.",
    add_objective: "+ Add Objective",
    add_key_result: "Add Key Result",
    delete_file: "Delete file",
    add_category: "+ Add Category",
    add_milestone: "add milestone",
    all_categories: "All Categories",
    all_kpis: "All KPIs",
    categories: "Categories",
    add: "Add",
    adapt_dashboard_modal: "Adapt dashboard",
    add_new_kpis: "Add new KPIs",
    create_new_kpi: "Create new KPI",
    order_kpis: "Adjust order of KPIs",
    create_objective: "Add new objective",
    title_change_log: "Change Log View",
    create_key_result: "Add Key Result",
    add_kpis: "Add new kpis",
    key_result_title: "Name",
    key_results: "Key Results",
    no_objectives: "-",
    created_at: "Created at",
    last_updated: "Last updated",
    updated: "Updated",
    corporate_objectives_time_frame_year: "Corporate objectives are created for the selected year.",
    category_objectives_time_frame_quarter:
      "Category objectives are created for the selected quarter.",
    assignment: "Assignment",
    metrics: "Metrics",
    type: "Type",
    objective_title: "Objective title",
    recommendations: "Recommendations",
    page_not_exist: "This page does not exist",
    api_integration_established: "API connection established",
    start_integration: "Start Integration",
    start_data_wizard: "Start Wizard",
    integration_state_initial: "Not Integrated",
    integration_state_wizard: "Requires Data Wizard",
    integration_state_integrated: "Integrated",
    integration_state_error: "Integration has errors",
    integration_added: "Integration added successfully",
    integration_wizard: "Integration wizard",
    disconnect_api: "Disconnect API",
    delete_wizard_data: "Delete Wizard Data",
    delete_selection: "Delete Selection",
    overview_wizard_title: "Summary mapping",
    reupload_data: "Re-Upload Excel",
    active_issues: "Active issues",
    inactive_issues: "Inactive issues",
    data_issues: "Data issues",
    showing_data_issues: "Showing data issues for",
    back_to_overview: "Back to overview.",
    no_inactive_issues: "There are currently no inactive issues.",
    no_active_issues: "There are currently no active issues.",
    no_data_issues: "Currently no data issues identified- Good job!",
    reactivate_this: "Do you really want to reactivate this",
    archive_this: "Do you really want to archive this",
    data_issue: "data issue",
    no_matching_results: "There are no matching results for your search.",
    archive_issue:
      "Archiving it will move it to the 'Inactive issues' tab and it will no longer be displayed in this list.",
    reactivate_issue:
      "Reactivating it will move it to the 'Active issues' tab and it will no longer be displayed in this list.",
    confirm: "Confirm",
    at: "at",
    time: "Time",
    confidence_level: "Confidence Level",
    potential_impact: "Potential Impact",
    success_likelihood: "Success likelihood",
    upload_reporting: "Upload new reporting",
    upload_planning: "Upload new planning",
    year: "Year",
    years: "Years",
    year_cum: "Year ∑",
    upload_date: "Upload Date",
    download_template: "Download template",
    no_data: "-",
    all_statuses: "All statuses",
    sec_unit: "s / m / h / d / mo / y",
    base_units: "Base Units",
    use_the_format: "Use the format",
    sec_popover: "• s = seconds",
    min_popover: "• m = minutes",
    h_popover: "• h = hours",
    d_popover: "• d = days",
    mo_popover: "• mo = months",
    y_popover: "• y = years",
    eur_k_popover: "• k = thousands",
    eur_m_popover: "• m = millions",
    not_available: "n/a",
    add_button: "Add",
    not_assigned: "Not Assigned",
    not_assigned_user_filter: "(Not Assigned)",
    remove_button: "Remove",
    add_action_to_list: "Add action to list",
    collapse_all: "Collapse all",
    expand_all: "Expand all",
    show_monthly_values: "Show monthly values",
    create_update_users: "Create or Update Users",
    create_user: "Create User",
    edit_user: "Update User",
    send_password_reset_note: "Please assign a role before sending an invitation.",
    upload_users_template: "Upload user list",
    download_users_template: "Download user list",
    success_message_users_upload: "Upload successful.",
    erroneous_message_users_upload:
      "Some entries were not added because they were missing user details. Please check the file and upload it again.",
    all_users: "All Users",
    check_all: "(Select All)",
    check_all_filtered_items: "(Select All Filtered Items)",
    uncheck_all: "Uncheck All",
    all_items: "All items",
    all_changes: "All changes",
    no_data_available: "There is no data for this filter",
    all_time_ranges: "All time ranges",
    select_user: "Select User",
    select_item: "Select item",
    select_change: "Select change",
    select_time_range: "Select time range",
    newest_oldest: "Newest to oldest",
    update_ranges: "All update ranges",
    oldest_newest: "Oldest to newest",
    no_data_time_range: "No data for this time range.",
    objectives_overview: "Objectives Overview",
    okr_list_view: "Okr List View",
    okr_team_analytics: "Okr Team Analytics View",
    okr_change_log: "Okr Change Log View",
    users: "Users",
    user_1: "User",
    teams: "Teams",
    members: "Members",
    changes: "changes",
    change_1: "change",
    items: "Items",
    item_1: "item",
    YES: "Yes",
    NO: "No",
    date: "Date",
    filename: "File Name",
    author: "Author",
    name: "Name",
    change_department: "Change a department or team",
    confirm_delete: "Confirm delete",
    confirm_delete_upload_message:
      "Are you sure you want to delete the upload '$filename' from $name on $date? This action cannot be undone, all related data will be removed.",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    integration_started_rerun: "Integration started rerun.",
    something_went_wrong: "Something went wrong. Please try again.",
    rerun_message:
      "{message} Last rerun was at {formattedTimestamp} by user '{user}' for integration '{integration}'. You must wait {formattedTime} minute{plural} before re-running.",
    small_edit: "edit",
    close: "Close",
    upload_excel: "Upload Excel",
    copy: "Copy",
    move: "Move",
    copy_objective: "Copy Objective",
    include_key_results: "Include Key Results",
    include_milestones: "Include Milestones",
    reset_progress: "Reset Progress",
    unchanged: "unchanged",
    delete: "Delete",
    next: "Next",
    previous: "Previous",
    status: "Status",
    multiple_statuses: "Statuses",
    all_statusses: "Status",
    select_status: "Select status",
    onTrack: "On Track",
    atRisk: "At Risk",
    offTrack: "Off Track",
    none: "None",
    contains_errors: "contains errors",
    hide_details: "Hide Details",
    show_details: "Show Details",
    restart_wizard: "Restart Wizard",
    upload_unsuccessful: "The upload could not be completed. Error: $error",
    success: "Success",
    category: "Category",
    done: "Done",
    kpi: "KPI",
    kpi_in_keur_per: "KPI in k€ / %",
    kpi_visibility: "Show or Hide KPIs",
    related_kpi: "Related KPI",
    see_related_kpis: "See related KPIs",
    related_kpis: "Related KPIs",
    parent_kpi: "Parent-KPI",
    same_level_child_kpi: "Same level Child-KPI",
    child_kpi: "Child-KPI",
    refresh_kpi_calculations: "Refresh KPI calculations",
    refresh_kpi_calculations_success: "KPIs successfully recalculated",
    refresh_kpi_calculations_error: "KPIs recalculating error",
    unit: "metr.",
    edit_data_input: "Edit data input",
    send_comment: "Send Comment",
    report_issue: "Report Issue",
    send_report_issue: "Report an issue to our admins",
    subject: "Subject",
    message: "Description of the bug",
    attach_screen_shot: "Attach a screenshot",
    submit: "Submit",
    formula: "Formula",
    current_month: "Current Month",
    current_year: "Current Year",
    current_quarter: "Current Quarter",
    selected_range: "Selected Range",
    selected_choices: "Selected choices",
    please_select: "Please Select",
    plan_variance: "Planned variance",
    sec_base_unit_placeholder: "30s or 15m or 18.5h",
    eur_base_unit_placeholder: "500 or 3k or 7.2m",
    bin_unit: "Yes or No",
    trend_duration: "Trend ($duration)",
    trends: "Trends",
    sort_dashboards: "Sort Dashboards",
    sort_custom_dashboards: "Sort Custom Dashboards",
    bin_error_message:
      "Found invalid values, please use 0 or 1 for this input, or type '-' in order to remove data for KPIs: ",
    pos_error_message:
      "Found invalid values, please use integers for this input, or type '-' in order to remove data for KPIs: ",
    missed_forecast_data: "Missed Forecast Value",
    generic_error: "An error occurred, please reload the page. Message: $message",
    retry_error: "An error occurred, try again in a few minutes. Error message: $message.",
    non_fix_error:
      "An error occurred, if the error persists please let us know. Error message: $message",
    invalid_value_error:
      "Found invalid values, please use english number format with decimal points, or type '-' in order to remove data.",
    monthly_invalid_value_error:
      "Found invalid values, please use english number format with decimal points, or type '-' in order to remove data for KPIs: ",
    delete_planning_value_hint: "Type '-' in order to remove data",
    favorite: "Favorite",
    trend: "Trend",
    from: "From",
    to: "To",
    visible: "Visible",
    visibility_change_warning:
      "These settings apply to all users in your company. Note that no data will be collected or displayed for invisible KPIs. Existing data will not be deleted.",
    yearly_aggregation_rounding_hint:
      "Exact yearly value is shown, aggregation of rounded monthly values might lead to a slightly different result.",
    month: "Month",
    quarter: "Quarter",
    quarters: "Quarters",
    same_quarter_one_year_before: "Same quarter 1 year before",
    add_dashboard: "Add Dashboard",
    add_reporting_company: "Add Reporting Company",
    custom: "Custom",
    months: "Months",
    hide: "Hide",
    community_benchmark: "Community Benchmark",
    action_list: "Action List",
    deal_size: "Comparative Deal Size",
    sales_pipeline: "Sales Pipeline",
    sales_pipeline_dev: "Sales Pipeline Development",
    recurring_revenue: "Recurring Revenue",
    list_view: "List View",
    deepdrills: "Deep Drills",
    edit_deep_drills: "Edit Deep Drills",
    add_deep_drills: "Add Deep Drills",
    no_deep_drills_selected: "No deep drills selected. Click",
    to_add_deep_drills: "to add deep drills to your dashboard.",
    show_top_10: "Top 10 deals",
    show_bottom_10: "Bottom 10 deals",
    EUR: "€",
    KEUR: "k€",
    MEUR: "M€",
    USD: "$",
    KUSD: "k$",
    MUSD: "M$",
    GBP: "£",
    KGBP: "k£",
    MGBP: "M£",
    CHF: "CHF",
    KCHF: "kCHF",
    MCHF: "MCHF",
    NOK: "NOK",
    KNOK: "kNOK",
    MNOK: "MNOK",
    SEC: "sec",
    MIN: "min",
    HRS: "hours",
    DAY: "days",
    MON: "months",
    YRS: "years",
    PER: "%",
    POS: "#",
    BIN: "y/n",
    MONTHLY: "Monthly",
    DAILY: "Daily",
    WEEKLY: "Weekly",
    YEARLY: "Yearly",
    QUARTERLY: "Quarterly",
    BIWEEKLY: "Bi-weekly",
    FIRST: "first",
    LAST: "last",
    SECOND_LAST: "Second last",
    THIRD_LAST: "Third last",
    DAY_OF_MONTH: "day",
    MO: "Monday",
    TU: "Tuesday",
    WE: "Wednesday",
    TH: "Thursday",
    FR: "Friday",
    SA: "Saturday",
    SU: "Sunday",
    DAYS_BEFORE_DUE: "days",
    WORKDAYS_BEFORE_DUE: "workdays",
    before_due_date: "before due date",
    calendar_week_abr: "CW",
    in_keur_per: "KPI in k€ / %",
    in_kusd_per: "KPI in k$ / %",
    in_kgbp_per: "KPI in k£ / %",
    in_kchf_per: "KPI in kCHF / %",
    kpi_eur_per: "KPI in € / %",
    kpi_usd_per: "KPI in $ / %",
    kpi_gbp_per: "KPI in £ / %",
    kpi_chf_per: "KPI in CHF / %",
    add_to_favorites: "Add to favorites",
    remove_from_favorites: "Remove from favorites",
    edit_custom_kpi: "Review / Edit custom KPI",
    update_custom_kpi: "Update",
    delete_custom_kpi: "Delete",
    show_kpi: "Review KPI",
    cw: "CW",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    administrator: "Administrator",
    admin: "Admin",
    c_level: "C-Level",
    delegate: "Delegate",
    employee: "Employee",
    external: "External",
    investor: "Investor",
    head_of_department: "Head of department",
    custom_permissions: "Customized",
    read: "Read",
    write: "Write",
    choose_file: "Choose a file or drop it here...",
    error_file: "Error uploading file",
    uploaded_and_store: "Uploaded and stored",
    username_login: "Click on login and provide username / client_id and password / client_secret",
    api_key: "API key",
    api_key_title: "Click on login and provide the api key",
    excel_upload: "Upload Excel file",
    oauth_method_title: "Click on login to perform the OAuth handshake",
    drop_credentials_title: "Upload JSON file containing the credentials",
    drop_files: "Drop file here...",
    dashboard_permissions: "Dashboard permissions",
    okr_permissions: "OKR permissions",
    data_management_permissions: "Data management permissions",
    kpi_value_permissions: "KPI value permissions",
    clear: "Clear",
    non_confidential: "Basic",
    confidential: "Confidential",
    confidentiality: "Confidentiality",
    strictly_confidential: "Strictly confidential",
    employee_okrs_permission: "Employee OKRs *",
    team_orks_permission: "Team OKRs *",
    corporate_okrs_permission: "Corporate OKRs / Scheduler",
    basic_kpi_values_permission: "Basic *",
    confidential_kpi_values_permission: "Confidential *",
    strictly_confidential_kpi_values_permission: "Strictly confidential",
    access_deep_drills: "Access deep drills",
    permissions_auto_included_hint: "* Automatically included with higher level permission",
    no_permission_to_access_content: "You don't have permission to access this content.",
    page_was_deleted: "Oops, this page was deleted.",
    n_items: "$number items",
    chatgpt_api_settings: "ChatGPT API Settings",
    chatgpt_first_note:
      "ChatGPT API is a powerful tool that businesses can use to enhance their operations and provide better services to their customers. It is an artificial intelligence system based on GPT-3.5 architecture that can understand and respond to natural language queries and conversations.",
    chatgpt_second_note:
      "ValueWorks leverages ChatGPT in order to e.g., recommend OKR formulations based on existing Objectives and Key Results or deliver simple root-cause recommendations for underperforming KPIs. In doing so, no numeric (and thus confidential) company data will be shared. What will be shared, however, are Objective or Key Result names, or rough indications of KPI performance such as “performs better than previous month”.",
    chatgpt_third_note:
      "To use ChatGPT API  for these purposes, you will need to agree to our data processing agreement. This agreement outlines the terms and conditions under which we process and store your data, including any personal information that may be shared through the API.",
    edit_company_info: "Edit company info",
    is_data_processing_agreement_accepted:
      "I hereby agree to the data processing agreement (download below)",
    is_authority_confirmed:
      "I have the authority to agree to the data processing agreement (e.g., managing director, Procura)",
    chatgpt_by_agreeing_to_conditions:
      "By agreeing to these conditions, you will enable additional ChatGPT-based functionality within ValueWorks.",
    chatgpt_more_info:
      "For more information, please click on the button below to download a copy of the data processing agreement.",
    changes_c: "Changes",
    user: "User",
    chatgpt_no_logs: "There are no logged changes so far.",
    download_blank_chatgpt_pdf: "Download blank data processing agreement",
    generate_new_version: "Generate new version",
    download: "Download",
    company_info: "Company info",
    settings: "Settings",
    company_name: "Company name",
    street_name: "Street name",
    zip_code: "ZIP/PLZ-code",
    city_name: "City",
    country_name: "Country",
    general_settings: "General Settings",
    currency_settings: "Currency Settings",
    currency_settings_explanation:
      "The corporate currency is the currency that will be displayed throughout the ValueWorks platform. In case you would like to change it, please contact your customer success contact in order to inquire about the process.",
    currency_settings_explanation_2:
      "By default, the corporate currency has an exchange rate of one. By adding additional currencies, you will allow the system to transform data from integrations (e.g., Salesforce) to the corporate currency. Therefore, when adding a new currency, please enter the exchange rate of the new currency to the corporate currency.",
    currency_settings_explanation_3:
      "Please note that the exchange rates are fixed, and will not be updated automatically at any point. Please also note that financial data is to be uploaded in the corporate currency in order to avoid effects of exchange rate fluctuations.",
    user_management: "User Management",
    okr_settings: "OKR Settings",
    chatgpt_settings: "ChatGPT API Settings",
    okr_settings_status_settings_explanation:
      "With the status settings, you can define what status options are available to the users of your organization. Be aware that your changes will also affect historical data and current OKR cycles. Please refrain from changing the status options during a running OKR cycle or communicate all changes to all users well in advance.",
    okr_settings_table_explanation:
      "With this table, you can change the name of existing status options, as well as the colors associated with them. Additionally, you can add a new status option and assign a color to it.",
    status_id: "Status Id",
    status_name: "Status Name",
    status_color: "Status Color",
    add_status_option_plus: "+ Add status option",
    enter_status_name: "Please enter new status",
    reset_to_default: "Reset to default",
    confirm_remove_status_message:
      "Please confirm that you want to delete the status option '$selectionName' - this cannot be undone. If you decide to delete this status option, all OKRs with this status will be set to “None”",
    kpi_management: "KPI Management",
    link: "Link",
    add_ai_generated_kr: "Add AI-generated Key Result recommendations for your objective:",
    make_suggestion: "Make suggestion",
    select_origin: "Select Tool",
    integration: "Integration",
    project_board: "Project/Board",
    select_project_board: "Select Project/Board",
    item: "Item",
    show_planning_values_and_deviation: "Planning",
    planning_budget: "Plan (Budget)",
    weighted_pipeline: "Weighted Pipeline",
    show_labels: "Labels",
    lead_source: "Lead Source",
    lead_source_owner: "Leads by Owner",
    input_code: "Please input the received code",
    code: "Code",
    error: "Error",
    resend_code: "Resend code",
    two_factor_auth: "Two-Factor Authentication",
    phone_number: "Phone Number",
    connected_phone_number: "Connected phone number",
    change_phone_number: "Change phone number",
    add_phone_number: "Add phone number",
    activate_phone_number: "Activate phone number",
    details: "Details",
    active: "Active",
    inactive: "Inactive",
    sort_items: "Sort items",
    sort: "Sort",
    sort_asc: "Ascending",
    sort_desc: "Descending",
    filter: "Filter",
    apply_filter: "Apply Filter",
    clear_filter: "Clear Filter",
    sort_objectives_and_keyresults_for: 'Sort Objectives and Key Results for "$departmentName"',
    expand_items: "Expand items",
    expand_collapse: "Expand & Collapse",
    show_objectives: "Show Objectives",
    show_key_results: "Show Key Results",
    show_milestones: "Show Milestones",
    select_displayed_level: "Select displayed level",
    investor_reporting: "Investor Reporting",
    investor_reporting_subject: "Subject",
    investor_reporting_empty_subject_error: "Subject is a required field",
    investor_reporting_default_subject: "ValueWorks Investor Reporting | ",
    no_reports: "No reports",
    manage_reports: "Manage Reports",
    approve_report: "Approve Report",
    send: "Send",
    "operational-dashboard": "Operational Dashboard",
    "financial-dashboard": "Financial Dashboard",
    "cap-table": "Cap Table",
    add_new_version: "+ Add new version",
    draft: "Draft",
    approved: "Approved",
    sent: "Sent",
    add_a_new_report: "Add a new report",
    edit_report: "Edit report",
    start_from_scratch: "Start from scratch",
    select_template: "Select template",
    report_name: "Report name",
    define_dates: "Define dates",
    define_kpis: "Define KPIs",
    select_recipients: "Select recipients",
    include_data_from: "Include data from",
    include_data_to: "Include data to",
    confirm_delete_investor_reporting:
      'Are you sure you want to delete "$report_name" investor reporting? This action is permanent and cannot be reversed.',
    confirm_approve_investor_reporting: "Approve Investor Report",
    confirm_approve_investor_reporting_text:
      "After approving, KPIs and time ranges can not be changed anymore. After sending, only recipients can be edited.",
    send_out_current_version: "Send out current version",
    send_reporting_message: "Message",
    copied_to_clipboard: "Copied to clipboard",
    view_recipients: "View Recipients",
    deleted: "deleted",
    customize_list_view_sticky_warning: "The first column will always be sticky.",
    reports: "Reports",
    portfolio_name: "Company name",
    service_name: "Service name",
    django_service_name: "Service name (Django)",
    add_reporting_company_error: "There was a problem adding this reporting company",
    portfolio_settings: "Portfolio Management",
    REQUESTED: "Requested",
    DENIED: "Denied",
    AUTHORIZED: "Authorized",
    holding_management: "Holding Management",
    undefined: "Undefined",
    authorize: "Authorize",
    authorized: "Authorized",
    no_portfolios: "No Reporting Companies",
    show: "Show",
    period: "Period",
    kpis: "KPIs",
    email_sent: "Email sent",
    add_new_recipient: "Add New Recipient",
    holding_instances: "Holding Instances",
    K: "k",
    M: "M",
    cohort_average: "Average",
    cohort_sum: "Sum",
    cohort_median: "Median",
    source: "Source",
    manual_upload: "Manual upload",
    calculated: "Calculated",
    my_activities: "My activities",
    all_activities: "All activities",
    about_otp: "Secure your account with TOTP two-factor authentication",
    setup_2fa: "Set-up",
    setup_otp: "Set-up TOTP",
    setup_2fa_modal: "Two-Factor Authentication (2FA)",
    remove_2fa: "Remove",
    enabled: "Enabled",
    disabled: "Disabled",
    totp: "Time-based One-Time Password (TOTP)",
    "2fa": "2FA",
    "2fa_organization_policy": "2FA Organization Policy",
    mandatory_for_all: "Mandatory for all",
    optional_for_all: "Optional for all",
    "2fa_mandatory": "Mandatory 2FA",
    otp_setup_configure_title: "Configure Google Authenticator or Authy",
    otp_setup_configure_step_1: "Install Google Authenticator, Authy or any authenticator app",
    otp_setup_configure_step_1_devices: "(iOS - Android).",
    otp_setup_configure_step_2: 'In the authenticator app, select "+" icon.',
    otp_setup_configure_step_3:
      'Select "Scan a QR code" and use the phone\'s camera to scan this QR code',
    otp_setup_scan: "Scan QR Code",
    otp_setup_secret: "Or Enter Secret Key Into Your App",
    otp_setup_secret_label: "Secret Key",
    otp_setup_verify: "Verify Code",
    otp_setup_verify_label: "Please input the authentication code from the authenticator app",
    otp_settings_dont_remove: "You are not allowed to remove 2FA",
    kpi_tree: "KPI Tree",
    no_activities: "No activities",
    kpi_comparison: "KPI Comparison",
    no_kpis: "No KPIs",
    no_kpi_selected: "No KPI selected",
    comparison: "Comparison",
    off: "Off",
    create_new_report: "Create new report",
    operational_time_range: "Operational Time Range",
    profit_loss_time_range: "Profit & Loss Time Range",
    delete_report: "Delete Report",
    investor_report_oops:
      "Oops, looks like the report you are trying to access doesn't exist, or you don't have access to it",
    hidden: "hidden",
    reportname_taken: "This report name is already taken",
    kpi_timerange_from: "Kpi time range from",
    select_date: "Select Date",
    different_timerange_financial_reporting: "Use different time range for financial reporting",
    approve: "Approve",
    approve_and_send: "Approve & Send",
    report_settings: "Report Settings",
    operational_reporting: "Operational reporting",
    financial_reporting: "Financial reporting",
    investor_reporting_manage_section_explanation:
      "You can add as many text, KPI and deep drill sections as you want, rename and remove every section and order them as you want. Attachments can only appear once.",
    choose_template: "Choose Template",
    add_section: "Add section",
    save_as_template: "Save as new template",
    text: "Text",
    attachments: "Attachments",
    new_template_name: "New template name",
    no_notes: "No Notes",
    no_attachments: "No attachments",
    kpi_graph_last_year_tooltip: "Last Year",
    kpi_graph_planned_tooltip: "Planned",
    kpi_graph_real_tooltip: "Actual",
    kpi_recommendations: "Recommendations",
    zip_wait_checks: "The ZIP file is being checked...",
    zip_wait_delete: "The ZIP file is being deleted...",
    missing_files_zip:
      "Sorry, but it appears that the ZIP file provided is missing some of the required files. Please repeat the upload with the missing CSV files: $files",
    zip_file_excedees_size:
      "The file you are trying to upload exceeds the maximum allowed size of $size MB.",
    duplicate: "Duplicate",
    share: "Share",
    customize_financial_categories: "Customize KPI financial categories",
    attachments_about_note:
      "Please submit relevant materials and documents, ensuring that their combined size does not exceed $allowedSizeInMBMB.",
    attachments_section_size_exceeded:
      "Cannot add the attachment, because it exceeds the allowed size.",
    text_section_saved: "Changes saved",
    text_section_saving: "Saving...",
    financial_reporting_timerange: "Financial reporting time range",
    placeholder_note: "Share your updates here",
    glossary_placeholder: "Insert note here",
    select_year: "Select Year",
    default_recommendations: "Default Recommendations",
    ai_generated_recommendations: "AI-generated recommendations",
    enable_ai_generated_recommendations: "Enable AI-generated recommendations for this KPI",
    kpi_gpt_recommendation_confirmation_title: "Note for using KPI recommendation engine.",
    kpi_gpt_recommendation_confirmation_message:
      "Please be aware that by using this functionality, you will be sharing this KPI's name with a third-party provider; Currently no additional data is shared, but we will incrementally extend improve the system in order to give more accurate information. You will be properly informed about these changes.",
    kpi_gpt_recommendation_confirmation_note:
      "If this functionality is available, this means that your system admin has enabled the feature. Use it at your own discretion.",
    make_new_suggestion: "Make new suggestion",
    select_kpis: "Select KPIs",
    gpt_settings_tokens_usage: "Tokens Usage",
    gpt_tokens_usage_threshold_warning:
      "You have used more than $used_per of your available OpenAi tokens. If you need more tokens please contact support@valueworks.ai.",
    show_issues: "Show issues",
    ignore_issue: "Ignore issue",
    reactivate_data_issue: "Reactivate issue",
    issues: "Issues",
    issue: "Issue",
    select_issue: "Select issue",
    all_issues: "All Issues",
    no_lineitems: "Opportunity missing line items",
    no_start_date: "Line item missing start date",
    no_associated_company_id: "Opportunity missing associated company",
    more_than_one_associated_company_id: "Opportunity has multiple companies linked to it",
    lead_status_other_than_mql_or_sql: "Contact/Lead has status other than MQL or SQL",
    list_view_filter_auto_apply: "Auto Apply",
    kpi_alert: "KPI Alert",
    kpi_alert_stage_1_text_1:
      'Please fill in the lower and upper boundary for the alert regarding this KPI. Click the "Next"-Button afterwards to get to the next page.',
    kpi_alert_stage_1_text_2: "If boundary value is empty, no boundary will be set.",
    kpi_alert_stage_2_text_1:
      "The monthly offset determines what monthly value is relevant for the alert. By default, the current month will be checked (offset = 0), but you can also check the previous month (offset = -1) etc.",
    kpi_alert_stage_2_text_2:
      "The alert frequency defines how often the alert will be sent. Consequentially, daily alerts will notify you daily if your boundaries are broken. The alert is always sent at the end of the selected timeframe, i.e., daily alerts will be triggered at the end of the day.",
    lower_boundary: "Lower boundary",
    upper_boundary: "Upper boundary",
    monthly_offset: "Monthly offset",
    alert_frequency: "Alert frequency",
    update: "Update",
    dashboard_type: "Dashboard type",
    regular_dashboard: "Regular",
    dynamic_dashboard: "Dynamic",
    untitled_section: "Untitled section",
    manage_kpis: "Manage KPIs",
    delete_section: "Delete Section",
    deepdrill_not_available_for_custom_range:
      "This deepdrill is not available with custom date range picker. Please select monthly, quarterly or yearly view to see the deepdrill.",
    export: "Export",
    export_excel: "Excel export",
    export_pdf: "PDF export",
    confirm_delete_integration:
      "Please confirm that you want to delete this integration - this cannot be undone.",
    confirm_reset_api:
      "Please confirm that you want to reset this API connection - this cannot be undone.",
    confirm_reset_data:
      "Please confirm that you want to delete this wizard data - this cannot be undone.",
    include_pdf_as_attachment: "Include the PDF of this report as attachment",
    choose_or_drop_file: "Choose a file or drop it here...",
    drop_file: "Drop file here...",
    stageProbability: "Stage Probability",
    dealProbability: "Deal Probability",
    in_sec: "In seconds",
    in_min: "In minutes",
    in_hrs: "In hours",
    in_day: "In days",
    in_mon: "In months",
    in_yrs: "In years",
    seconds: "seconds",
    minutes: "minutes",
    hours: "hours",
    in_valueworks_TIME: "ValueWorks Standard",
    timerange: "Time Range",
    aggregated: "Aggregated",
    please_select_other_custom_cohort:
      "Please select the monthly, quarterly or yearly view to be able to see Cohort Analysis Chart.",
    remaining: "Remaining",
    blanks: "(Blanks)",
    charts: "Charts",
    bar_chart_name: "Bar chart",
    bar_chart_desc:
      "A bar chart is used to visually represent and compare categorical data or discrete values. It provides a clear and concise overview of the distribution or relationship between different categories or variables.​",
    stacked_bar_chart_name: "Stacked bar chart",
    stacked_bar_chart_desc:
      "A stacked bar chart visually represents the composition of a whole by stacking different categories within each bar, aiding in the understanding of total magnitude and relative proportions.",
    grouped_bar_chart_name: "Grouped bar chart",
    grouped_bar_chart_desc:
      "A grouped bar chart is used to compare multiple categories or variables within subgroups. It allows for easy comparison and analysis of data across different groups or subcategories.",
    percentage_stacked_bar_chart_name: "Percentage stacked bar chart",
    percentage_stacked_bar_chart_desc:
      "A percentage stacked bar chart is used to display the relative proportions of different categories or variables within a whole. It illustrates the distribution of data as percentages, helping to compare the contribution of each category to the total.",
    create: "Create",
    coming_soon: "Coming soon",
    please_choose_chart_type: "Please choose chart type",
    orientation: "Orientation",
    primary_attribute: "Primary attribute",
    primary_operator: "Primary operator",
    secondary_attribute: "Secondary attribute",
    grouping: "Grouping",
    save_and_generate: "Save & Generate",
    field: "Field",
    horizontal: "Horizontal",
    vertical: "Vertical",
    text_sum: "Sum",
    count: "Count",
    text_average: "Average",
    min: "Min",
    max: "Max",
    operation: "Operation",
    date_grouping: "Date grouping",
    exact_date: "Exact date",
    day: "Day",
    group_by: "Group by",
    add_chart: "+ Add chart",
    confirm_delete_chart:
      "Are you sure you want to delete this chart? This action is permanent and cannot be reversed.",
    data_management: "Data Management",
    connect: "Connect",
    remove_integration: "Remove Integration",
    continue: "Continue",
    manage: "Manage",
    reconnect: "Re-connect",
    or: "or",
    login_with_microsoft: "Login with Microsoft",
    link_microsoft_account: "Link Microsoft Account",
    unlink_account: "Unlink Account",
    you_have_linked_account: "You have successfully linked a $provider account.",
    sso_azure: "Microsoft",
    sso: "Single Sign-On",
    sso_user_not_found: "Requested user not linked with any existing ValueWorks user",
    generate: "Generate",
    cumulative_sum: "Cumulative Sum",
    filter_owner: "Filter Owner",
    pipeline: "Pipeline",
    show_fractional: "Show fractional",
    all_conversions: "All conversions",
    only_positive_conversions: "Only positive",
    only_negative_conversions: "Only negative",
    please_select_other_custom_conversion:
      "Please select the monthly, quarterly or yearly view to be able to see Convserion Matrix.",
    conversion_rate: "Conversion Rate",
    conversion_matrix_footnote:
      "Conversion Matrix always compares the most recent snapshots from both ranges.",
    basic: "Basic",
    complex: "Complex",
    multi_dimension_upload_info_1:
      "You need to upload the export you want to use for the KPI calculation.",
    multi_dimension_upload_info_2:
      "In addition to the desired dimensions (e.g., country, product), the file must include:",
    multi_dimension_upload_info_id: "an ID column",
    multi_dimension_upload_info_date: "a date column",
    multi_dimension_upload_info_value: "a value column",
    multi_dimension_upload_info_3:
      "If these columns are available, complex KPIs and their filters can be generated.",
    date_column: "Date column",
    value_column: "Value column",
    id_column: "ID column",
    dimension: "Dimension",
    no_options: "No options",
    no_info: "No info",
    no_info_available: "No information available",
    filter_by: "Filter by",
    selected: "selected",
    select_all_visible_kpis: "Select all visible KPIs",
    deselect_all_visible_kpis: "Clear selection",
    saving: "Saving...",
    all_owners: "All Owners",
    no_owner: "No Owner",
    expanded_customers: "Expanded Customers",
    unchanged_customers: "Unchanged Customers",
    new_customers: "New Customers",
    churned_customers: "Churned Customers",
    reduced_customers: "Reduced Customers",
    change_rr: "Recurring Revenue Change",
    show_change: "Revenue Changes",
    reset_2fa: "Reset 2FA",
    reset_otp_confirmation_text:
      "Are you sure you want to delete 2FA data for the user with the email $email?",
    reset_otp_not_available: "2FA is not set-up",
    half_year: "Half-Year",
    rrd_changes: "Changes",
    microsoft_login_note:
      "Note: In order to log in with Microsoft please link an account in the ValueWorks user management settings.",
    successful_upload: "Successful upload",
    contains_warnings: "contains warnings",
    upload_partially_successful: "Upload partially successful, contains errors",
    status_details: "Status details",
    select_type: "Select type",
    select_month: "Select month",
    warning: "Warning",
    new_value: "New value",
    changed_value: "Changed value",
    datev_overview_integration_wizard_title: "Manage financial statements",
    datev_setup_financial_statements: "Setup of financial statements",
    datev_setup_cogs_method: "Profit & Loss Statement in COGS method",
    datev_setup_total_cost_method: "Profit & Loss Statement in Total Cost method + Balance Sheet",
    datev_upload_of_raw_data: "Upload of raw data",
    datev_list_of_bookings_legacy: "List of bookings (legacy upload)",
    datev_list_of_open_positions: "List of open positions (OPOS)",
    gdpdu: "GDPDU",
    datev_setup_opos: "List of open positions (OPOS)",
    datev_start_setup: "Start setup",
    datev_show_setup: "Show setup",
    upload_file: "Upload file",
    manage_integration: "Manage Integration",
    not_started: "Not started",
    setup_complted: "Setup completed",
    gdpdu_definition:
      "Grundsätze zum Datenzugriff und zur Prüfbarkeit digitaler Unterlagen (GDPdU)",
    forecasting: "Forecasting",
    forecast_name: "Forecast name",
    forecast_source: "Forecast source",
    forecast_method: "Forecast method",
    timeframe_actuals: "Timeframe actuals",
    timeframe_forecast: "Timeframe forecast",
    activate_new_forecast_title: "Activate forecast",
    activate_new_forecast_content: "Are you sure you want to activate the forecast $name?",
    deactivate_forecast_title: "Deactivate forecast",
    deactivate_forecast_content: "Are you sure you want to deactivate the forecast $name?",
    toggle_activate_forecast_title: "Activate other forecast",
    toggle_activate_forecast_content:
      "Are you sure you want to deactivate the forecast $old and activate the forecast $new?",
    deactivate: "Deactivate",
    activate: "Activate",
    create_new_forecast: "Create new forecast",
    create_new_forecast_title: "Create new forecast",
    valueworks: "ValueWorks",
    google_sheets: "Google Sheets",
    select_forecast_source: "Select forecast source",
    select_forecast_method: "Select forecast method",
    linear_progression: "Linear progression",
    forecast_use_actuals_from: "Use actuals from",
    forecast_forecast_range_from: "Forecast range from",
    forecast_googlesheets_error_message:
      "No forecasting data was found in google sheets integration for this category.",
    linear_progression_error_message:
      "There is insufficient data for this forecasting method. Please ensure that there are at least 2 months of data available.",
    upload_forecasting: "Upload new forecasting",
    adjust: "Adjust",
    actuals: "Actuals",
    plannings: "Plannings",
    forecasted: "Forecasted",
    method: "Method",
    include_actuals: "Include actuals",
    manual_input: "Manual Input",
    show_deviation: "Show deviation",
    include: "Include",
    delete_forecast_value_hint: "Type '-' in order to remove data",
    forecast_adjusted_status: "(adj.)",
    delete_forecast_title: "Delete forecast",
    delete_forecast_content: "Are you sure you want to delete the forecast $name?",
    kpi_glossary: "KPI Glossary",
    notes: "Notes",
    description: "Description",
    validated: "Validated",
    in_validation: "In validation",
    not_validated: "Unvalidated",
    kpi_notes: "KPI Notes",
    kpi_status: "KPI Status",
    show_chart_full_size: "Full-sized chart",
    select_all: "Select all",
    knowledge_base: "Knowledge Base",
    message_copilot: "Message Copilot...",
    copilot_warning: "Copilot can make mistakes. Consider checking important information.",
    sync: "Sync",
    error_code_message: "Error $errorCode: $errorMessage",
    synching: "Synching",
    forecast_back_to_overview: "Back",
    upload_confirmation_title: "Upload confirmation",
    upload: "Upload",
    upload_confirmation_reporting_content:
      "Are you sure you want to upload $name for reporting values?",
    upload_confirmation_planning_content:
      "Are you sure you want to upload $name for planning values?",
    upload_confirmation_forecasting_content:
      "Are you sure you want to upload $name for forecasting values?",
    forecast_creation_checking_available_sources: "Checking available sources and methods",
    listview_exceeded_filter_unique_msg:
      "The filter functionality is disabled, as the number of unique values in this column exceeds the max. number of supported values of 1000.",
    listview_exceeded_size:
      "The size of the list view exceeds the maximum number of 10.000 entries. Please select a shorter timeframe or download the list for further analyses.",
    charts_categories_disabled_msg:
      "Some categories are not selectable as the count of their unique values exceedes a threshold.",
    download_here: "Download here",
    show_comments: "Include comments",
    include_planning: "Include planning",
    show_full_size_matrix: "Full-sized matrix",
    use_kpi_context: "Use KPI Context",
    using_kpi_context_info: "Using $kpi list view data from $from to $to",
    compare_with_type: "Compare with $type",
    projection_actual: "Projection",
    deviation_in: "Deviation in",
    bucket_1: "Month",
    bucket_3: "Quarter",
    bucket_6: "Half-Year",
    bucket_12: "Year",
    add_table: "Add Table",
    insertTable: "Insert Table",
    deleteTable: "Delete Table",
    addColumnBefore: "Add Column Before",
    addColumnAfter: "Add Column After",
    deleteColumn: "Delete Column",
    addRowBefore: "Add Row Before",
    addRowAfter: "Add Row After",
    deleteRow: "Delete Row",
    mergeCells: "Merge Cells",
    splitCell: "Split Cell",
    toggleHeaderRow: "Toggle Header Row",
    heading: "Heading",
    add_table_section: "Add Table Section",
    company_settings: "Company Settings",
    add_company_logo: "Add a company logo",
    add_company_logo_about:
      "You can upload your company logo here. It will be displayed alongside the ValueWorks logo in the upper left corner of the screen. It will be visible to all users. For an optimal experience, please upload both a logo optimized for a light and a dark background.",
    please_upload_a: "Please upload a",
    dark: "dark",
    light: "light",
    version_of_your_logo_here:
      "version of your logo here; We suggest a logo size of 5MB with a height of up to 60px.",
    preview: "Preview",
    filetype_not_allowed: "File type not allowed",
    file_too_big: "File too big",
    saved_logo_for_mode: "Logo for $mode mode",
    white: "light",
    datev_add_new_category: "+ Add new",
    please_map_categories_datev:
      "Please map the categories that are relevant for this branch of the organization.",
    profit_loss_statement_cogs_category_mapping: "Profit and loss statement (COGS method)",
    profit_loss_statement_total_cost_mapping: "Profit and loss statement (Total cost method)",
    balance_sheet_gdpdu_mapping: "Balance sheet (relevant for GDPdU)",
    please_select_a_category_datev: "Please select a category",
    datev_save_settings_modal_title: "Save settings",
    datev_save_settings_modal_content:
      "Are you sure you want to save the new settings for this integration? Re-mapping one method will delete the old setup data.",
    datev_delete_integration_modal_title: "Delete integration",
    datev_delete_integration_modal_content: "Are you sure you want to delete the integration?",
    no_category_setup_for_cogs: "No category has been set up for COGS method.",
    no_category_setup_for_gdpdu: "No category has been set up for GDPdU method.",
    no_category_setup_for_opos: "No category has been set up for OPOS.",
    select_financial_category: "Select financial category",
    configure: "Configure",
    configure_chart: "Configure this chart",
    labels: "Labels",
    values: "Values",
    last_year_values: "Last year values",
    planning_values: "Planning values",
    bars: "bars",
    delta: "Delta",
    no_benchmark_selected: "No benchmark selected",
    level: "Level",
    table: "Table",
    column: "Column",
    row: "Row",
    cell: "Cell",
    style: "Style",
    deepdrill: "Deep Drills",
    chart: "Charts",
    use_data_from: "Use data from",
    add_charts: "Add Charts",
    edit_charts: "Edit Charts",
    cagr: "CAGR",
    all_kpi_categories: "All KPI Categories",
    kpi_category: "KPI Category",
    kpi_categories: "KPI Categories",
    custom_listview_top: "Top",
    custom_listview_apply_globally: "Apply for all users",
    listview_filtered_out_of_total: "$filtered out of $total",
    forecasting_full_year: "Full year",
    forecasting_full_year_projection: "(projection)",
    forecasting_full_year_plan: "(plan)",
    forecastable: "Forecastable",
    full_year_sum: "Full year ∑",
    query_builder: "Query Builder",
    query_builder_permissions: "Query Builder Permissions",
    charts_no_date: "No date",
    full_year_dday: "Full year last",
    datepicker_settings: "Datepicker Settings",
    trend_settings: "Trend Settings",
    clear_all_filters: "Clear all filters",
    pivot_table_sync_fusion_name: "Pivot Table",
    pivot_table_sync_fusion_desc:
      "A pivot table quickly summarizes and organizes large data by grouping and calculating values like sums or averages. It helps you spot patterns and trends without manually sorting data. You can easily rearrange the information to get different views.",
    pivot_table_smart_html_name: "Pivot Table by Smart HTML",
    pivot_table_smart_html_desc: "Cheap but buggy (as of now)",
    forecast_with_this_name_already_exists: "A forecast with this name already exists.",
    valueworks_connect: "ValueWorks Connect",
    valueworks_connect_title: "Manage integrations across multiple ValueWorks instances",
    valueworks_connect_about:
      "By linking multiple ValueWorks systems together, you can easily switch between them without having to log in again. This does not disable your login credentials for the linked instance but allows you to switch effortlessly from your main instance to the linked instances. You can link as many ValueWorks instances as you want, all you need is login credentials for each linked instance.",
    valueworks_connect_start:
      "Start linking your first instance by adding the URL (e.g., of the login screen) below and logging in.",
    start_setup: "Start Setup",
    delete_instance: "Delete Instance",
    delete_instance_confirmation_text: "Are you sure you want to delete this instance?",
    redirecting: "Redirecting...",
    reporting_cadence: "Reporting Cadence",
    last_update: "Last update",
    rc_weekly: "Weekly",
    rc_bi_weekly: "Bi-Weekly",
    rc_monthly: "Monthly",
    rc_bi_monthly: "Bi-Monthly",
    rc_quarterly: "Quarterly",
    rc_semi_annually: "Semi-Annually",
    rc_annually: "Annually",
    kpi_outdated_x_ago: "KPI outdated: Last updated over $period ago",
    fy: "FY",
    p0: "P1",
    p1: "P2",
    p2: "P3",
    p3: "P4",
    p4: "P5",
    p5: "P6",
    p6: "P7",
    p7: "P8",
    p8: "P9",
    p9: "P10",
    p10: "P11",
    p11: "P12"
  },
  de: {}
};

export default new VueI18n({
  locale: "en",
  messages
});
