// Mapping of the route-names to actual view-components in this project
// Needed to translate the view/ route -label comming from the backend
const menuItemsMeta = {
  my_dashboard: {
    viewName: "personalDashboard",
    icon: "person"
  },
  financial_planning: {
    viewName: "financialPlanning"
  },
  operational_planning: {
    viewName: "operationalPlanning"
  },
  planning: {
    viewName: "planning",
    icon: "clipboard-data"
  },
  forecasting: {
    viewName: "forecastingOverview",
    icon: "binoculars",
    activeUrls: ["forecasting/"]
  },
  operational_dashboard: {
    viewName: "operationalDashboard"
  },
  strategic_dashboard: {
    viewName: "strategicDashboard"
  },
  financial_dashboard: {
    viewName: "financialDashboard"
  },
  custom_dashboard: {
    viewName: "customDashboard"
  },
  reporting: {
    viewName: "reporting",
    icon: "bar-chart-fill"
  },
  reporting_custom: {
    viewName: "reportingCustom",
    icon: "bar-chart-fill"
  },
  investor_reporting: {
    viewName: "investorReporting",
    icon: "table"
  },
  okr: {
    viewName: "objectivesOverview",
    activeUrls: [
      "objectives/overview",
      "objectives/okrlistview",
      "objectives/okrteamanalytics",
      "objectives/changelog",
      "objectives/teams/Marketing",
      "objectives/id/",
      "objectives/corporate"
    ]
  },
  okr_scheduler: {
    viewName: "okrScheduler"
  },
  execution: {
    viewName: "objectives",
    icon: "play-fill"
  },
  query_builder: {
    viewName: "queryBuilder",
    icon: "wrench"
  },
  data_input: {
    viewName: "dataInput"
  },
  integration_dashboard: {
    viewName: "integration"
  },
  settings: {
    viewName: "settings",
    icon: "sliders"
  },
  user_management: {
    viewName: "userManagement"
  },
  two_factor_auth: {
    viewName: "twoFactorAuth"
  },
  organizational_structure: {
    viewName: "organizationalStructure"
  },
  currency_settings: {
    viewName: "currencySettings"
  },
  kpi_management: {
    viewName: "kpiManagement"
  },
  kpi_glossary: {
    viewName: "kpiGlossary"
  },
  okr_settings: {
    viewName: "okrSettings"
  },
  chatgpt_settings: {
    viewName: "chatgptSettings"
  },
  company_settings: {
    viewName: "companySettings"
  },

  // holding
  portfolio_settings: {
    viewName: "managePortfolio"
  },
  holding_management: {
    viewName: "manageHolding"
  },
  holding_reporting: {
    viewName: "holdingReporting"
  },
  kpi_comparison: {
    viewName: "kpiComparison",
    icon: "speedometer2"
  },
  data_management: {
    viewName: "dataManagement",
    icon: "file-earmark-arrow-up-fill"
  }
};

/**
 *
 * @param {String} menuItem view/ route -label comming from the backend, that needs to be translated to a vue view-object
 * @param {Object} v vue context ex. containing the translation module i18n
 * @returns vue view-object
 */
export function mapRawMenuItemToLinks(menuItem, v) {
  return {
    ...menuItem,
    label: menuItem.isCustomLabel ? menuItem.label : v.i18n.t(menuItem.label),
    icon: menuItemsMeta[menuItem.label]?.icon,
    activeUrls: menuItemsMeta[menuItem.label]?.activeUrls,
    to: {
      name: menuItemsMeta[menuItem.to]?.viewName,
      params: menuItem.params
    },
    submenu: menuItem.submenu?.map(el => mapRawMenuItemToLinks(el, v))
  };
}

/**
 * For a given list of available menu items return a list of all available menu-items as a map, including the submenus
 * @param {Array} menuItems list of vue view-objects. Can be nested, if the item has submenu
 * @returns big object with keys being the view-names (submenu items including), with `true` value
 */
export function menuItemsToFlatMapWithLinkNames(menuItems) {
  return menuItems.reduce((o, el) => {
    if (el?.to?.name) {
      o = { ...o, [el.to.name]: true };
    }

    if (el?.submenu) {
      o = { ...o, ...menuItemsToFlatMapWithLinkNames(el.submenu) };
    }

    return o;
  }, {});
}
