import { getIntegrationsSummary } from "@/services/integrationService";
import { arraysEqual } from "./util";

const initialState = {
  summaries: {},
  lastFetched: null,
  lastIntegratedNames: []
};

const mutations = {
  setSummaries(state, { summaries, timestamp, integratedNames }) {
    state.summaries = summaries;
    state.lastFetched = timestamp;
    state.lastIntegratedNames = integratedNames;
  }
};

const actions = {
  async fetchSummaries({ state, commit }, integratedNames) {
    const now = Date.now();
    const tenMinutes = 10 * 60 * 1000;

    const isStale =
      now - (state.lastFetched || 0) > tenMinutes ||
      !arraysEqual(integratedNames, state.lastIntegratedNames);

    if (!isStale) {
      return state.summaries;
    }

    const response = await getIntegrationsSummary(integratedNames);
    const summaries = response.data;

    commit("setSummaries", {
      summaries,
      timestamp: now,
      integratedNames
    });

    return summaries;
  }
};

const getters = {
  integrationSummaries: state => state.summaries,
  lastFetchedTime: state => state.lastFetched,
  lastIntegratedNames: state => state.lastIntegratedNames
};

export const integrationSummariesModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
